import Map from "@components/Map";
import RestrictAccess from "@components/RestrictAccess";

function Index() {
  return (
    <RestrictAccess>
      <Map />
    </RestrictAccess>
  );
}

Index.getInitialProps = async function({ req, user, query, store, isServer }) {
  if (isServer) {
    return { user: req.user, query: req.query, store };
  }
  return { user, query, store };
};

export default Index;
